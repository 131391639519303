function mapUserObject(apiResponse) {
  const {
    status,
    message,
    abocustomer = false,
    customername,
    mailSha256,
    mailSha512,
    postcode,
  } = apiResponse

  return {
    status: status?.toLowerCase(),
    message,
    fullName: customername,
    mailSha256,
    mailSha512,
    postcode,
    isAboGroup: abocustomer,
  }
}

export { mapUserObject }
