/**
 * Function which makes requests
 *
 * @param {string} endpoint
 * @param {string} method
 * @param body
 * @param {object} appEndpoints 

* @returns {Promise<*>}
 */
export const fetchRequest = async (
  endpoint,
  method,
  body = {},
  appEndpoints
) => {
  if (
    typeof endpoint !== 'string' ||
    !Object.values(appEndpoints).includes(endpoint)
  ) {
    throw Error('Invalid endpoint: ' + endpoint)
  }
  if (typeof method !== 'string' || !['GET', 'POST'].includes(method)) {
    throw Error('Invalid method: ' + method)
  }

  const url = `${process.env.BACKEND_API_URL}/${endpoint}`
  let retMessage = {}
  try {
    const fetchOptions = {
      method,
      credentials: 'include',
      mode: 'cors',
    }
    if (method === 'POST') {
      fetchOptions.body = body
    }
    const response = await fetch(url, fetchOptions)

    if (response.status !== 200) {
      const { status, statusText } = response
      const error = new Error()
      error.code = status
      error.message = statusText
      error.stack = `fetchRequest(${method})`
      try {
        error.cause = await response.json()
      } catch (e) {
        console.warn(e) // JSON parse error
      }
      throw error
    }
    retMessage = await response.json()
  } catch (error) {
    retMessage = {
      status: 'error',
      message: error.toString(),
    }
  }

  return retMessage
}
