import React, { Component } from 'react'

import { BackendApiContext } from './index'
import MagentoApi from './MagentoApi'

export default class MagentoBackend extends Component {
  constructor(props) {
    super(props)
    this.apiInstance = new MagentoApi()
    this.apiFunctions = props.createApiFunctions(this.apiInstance)
  }

  render() {
    const { children } = this.props
    return (
      <BackendApiContext.Provider value={this.apiFunctions}>
        {children}
      </BackendApiContext.Provider>
    )
  }
}
