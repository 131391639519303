/**
 * Parts of a date format.
 *
 * @typedef DateFormat
 * @type {object}
 * @property {string} YEAR
 * @property {string} MONTH
 * @property {string} DAY
 */
export default {
  YEAR: 'yyyy',
  MONTH: 'MM',
  DAY: 'dd',
}
