import { isValidDateNumbers, isValidDateRange } from './isValidDate'
import DateFormat from './DateFormat'

/**
 * Match digits and minus sign.
 *
 * @type {RegExp}
 */
const matchNonDigits = /[^0-9\-]/g // eslint-disable-line no-useless-escape

/**
 * Parse a string into a valid Date object.
 *
 * Incomplete dates (e.g. without either year, month, and/or day) or invalid
 * date or month numbers cause an exception. See also: {@link isValidDate}.
 * Dirty strings are sanitized, see {@link matchNonDigits}.
 *
 * @param {string} str
 * @param {string} formatString - See 'DateFormat.js'
 * @param {string} splitChar
 * @param {ValidationOptions} [validationOptions] - Optional, see 'isValidDate.js'
 * @throws Error when a date is invalid
 * @returns {Date}
 */
const parseDate = (str, formatString, splitChar, validationOptions) => {
  const chars = str?.split(splitChar)
  const parts = formatString?.split(splitChar)
  //console.log('parseDate.stage.0', 'chars', chars, 'parts', parts, 'continue?', chars?.length === parts?.length)
  const cL = chars?.length
  if (cL === parts?.length) {
    // Ensure part indices are valid
    const iY = parts.indexOf(DateFormat.YEAR)
    const iM = parts.indexOf(DateFormat.MONTH)
    const iD = parts.indexOf(DateFormat.DAY)
    //console.log('parseDate.stage.1', [iY, iM, iD], '<', cL)
    if (iY > -1 && iY < cL && iM > -1 && iM < cL && iD > -1 && iD < cL) {
      // Clean part strings
      const sY = chars[iY].replace(matchNonDigits, '')
      const sM = chars[iM].replace(matchNonDigits, '')
      const sD = chars[iD].replace(matchNonDigits, '')
      //console.log('parseDate.stage.2', [sY, sM, sD], chars)
      if (sY.length && sM.length && sD.length) {
        // Parse numbers
        const y = parseInt(sY)
        const m = parseInt(sM)
        const d = parseInt(sD)
        //console.log('parseDate.stage.3', 'Checking:', y, m, d)
        // Ensure date is valid
        if (isValidDateNumbers(y, m, d)) {
          const date = new Date(y, m - 1, d, 0, 0, 0)
          if (y >= 0 && y < 100) {
            // 2-digit years map to 1900-1999, so we need to fix that. See
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#two_digit_years_map_to_1900_%E2%80%93_1999
            date.setFullYear(y)
          }
          //console.log('parseDate.stage.4', 'Parsed:', y, m, d, date, validationOptions)
          if (isValidDateRange(date, validationOptions)) {
            //console.log('parseDate.stage.5', date)
            return date
          }
        }
      }
    }
  }
  throw new Error('Invalid date')
}

export default parseDate
