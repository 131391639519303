import DateFormat from './DateFormat'

/**
 * Zero-pad a number with up to 4 digits. Numbers with more than 4 digits
 * return the number itself. 0 or less digits return an empty string.
 *
 * @param {any} n - The number to fill up with zeros
 * @param {number} [digits=2] - Number of digits to return
 * @returns {string}
 */
const zeroPad = (n, digits = 2) => {
  if (digits > 0) {
    return ('0000' + n).substr(-digits)
  } else if (digits > 4) {
    return n
  }
  return ''
}

/**
 * Format a date into a string. If date is not set, returns an empty string.
 *
 * @param {Date} dateObj
 * @param {string} formatString - See DateFormat.js
 * @param {string} splitChar
 * @returns {string}
 */
const formatDate = (dateObj, formatString, splitChar) => {
  let ret = ''
  if (dateObj instanceof Date) {
    if (typeof formatString !== 'string') {
      throw new Error(`Invalid format string "${formatString}"`)
    } else if (typeof splitChar !== 'string') {
      throw new Error(`Invalid split character "${splitChar}"`)
    } else if (formatString.indexOf(splitChar) <= 0) {
      throw new Error(
        `Split character "${splitChar}" not within format string "${formatString}"`
      )
    }
    const mapping = {
      [DateFormat.DAY]: dateObj.getDate(),
      [DateFormat.MONTH]: dateObj.getMonth() + 1,
      [DateFormat.YEAR]: dateObj.getFullYear(),
    }
    const entries = formatString.split(splitChar)
    entries.map((entry, index) => {
      ret += zeroPad(mapping[entry], entry.length)
      ret += index < entries.length - 1 ? splitChar : ''
    })
  }
  return ret
}

export default formatDate
