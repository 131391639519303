/**
 * Check if the given numbers form a valid date.
 *
 * @param {number} y - Year
 * @param {number} m - Month
 * @param {number} d - Day
 * @returns {boolean}
 */
const isValidDateNumbers = (y, m, d) => {
  if (typeof y === 'number' && typeof m === 'number' && typeof d === 'number') {
    //console.log('isValid', 'type.check')
    const isLeapYear = y && ((y % 4 === 0 && y % 100 !== 0) || y % 400 === 0)
    if (m < 1 || m > 12) {
      throw new Error('Invalid month')
    }
    //console.log('isValid', 'month.check')
    const daysOfMonth = [
      31,
      isLeapYear ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ]
    if (d < 1 || d > daysOfMonth[m - 1]) {
      throw new Error('Invalid day')
    }
    //console.log('isValid', 'days.check')
    return true
  }
  //console.log('isValid', false, y, m, d)
  return false
}

/**
 * @typedef ValidationOptions
 * @type {object}
 * @property {Date} minDate
 * @property {Date} maxDate
 */
/**
 * Check if a date object is within a given date range.
 *
 * @param {Date} date
 * @param {ValidationOptions} [options={}] - Optional
 * @throws {Error} When the given date is outside the given boundaries
 * @returns {boolean}
 */
const isValidDateRange = (date, options = {}) => {
  const { minDate, maxDate } = options
  if (date) {
    //console.log(date, minDate, maxDate)
    if (minDate && date < minDate) {
      throw new Error('Date too far in the past')
    } else if (maxDate && date > maxDate) {
      throw new Error('Date too far in the future')
    }
    return true
  } else {
    return false
  }
}

export { isValidDateNumbers, isValidDateRange }
